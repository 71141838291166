'use client'

import { signIn } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { useSearchParams } from 'next/navigation'

import CleverLoginButton from './CleverLoginButton'
import GoogleLoginButton from './GoogleLoginButton'
import ScoirLoginButton from './ScoirLoginButton'

interface ILoginWithButtonProps {
  provider: { [key: string]: any }
}

const LoginWithButton = ({ provider }: ILoginWithButtonProps) => {
  const t = useTranslations('Login')
  const searchParams = useSearchParams()
  const callbackUrl = searchParams.get('callbackUrl') || '/home'

  if (provider.id === 'clever') {
    return <CleverLoginButton callbackUrl={callbackUrl} />
  }
  if (provider.id === 'google') {
    return <GoogleLoginButton callbackUrl={callbackUrl} />
  }
  if (provider.id === 'scoir') {
    return <ScoirLoginButton callbackUrl={callbackUrl} />
  }

  return (
    <button type="submit" className="" onClick={() => signIn(provider.id, { callbackUrl: callbackUrl })}>
      {t('loginWith', { provider: provider.name })}
    </button>
  )
}

export default LoginWithButton
