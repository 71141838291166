import { signIn } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'

import GoogleLoginImage from './buttons/LogInWithGoogle.png'

interface IGoogleLoginButtonProps {
  callbackUrl: string
}

const GoogleLoginButton = (props: IGoogleLoginButtonProps) => {
  const { callbackUrl } = props
  const t = useTranslations('Login')
  return (
    <div
      className="relative flex h-[3rem] w-full sm:h-[4rem] items-center justify-center p-0"
      onClick={() => signIn('google', { callbackUrl: callbackUrl })}
    >
      <Image
        src={GoogleLoginImage}
        fill={true}
        alt={t('loginWith', { provider: 'Google' })}
        className="cursor-pointer object-contain sm:object-scale-down"
        sizes="100vw"
      />
    </div>
  )
}

export default GoogleLoginButton
