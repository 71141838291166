import { I18nString, I18nStringArray, Locales } from '../types'

/**
 * See README for details of how to update supported locales
 */
export const SUPPORTED_LOCALES = ['ar', 'en', 'es', 'fr', 'hi', 'ko', 'pt-BR', 'vi', 'zh-CN', 'ht'] as const

type LanguageNameMap = {
  [key in Locales[number]]: string
}

export const I18N_LANGUAGE_NAMES: LanguageNameMap = {
  ar: 'عربي',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  hi: 'हिंदी',
  ko: '한국인',
  'pt-BR': 'Portugues do Brasil',
  vi: 'Tiếng Việt',
  'zh-CN': '中文',
  ht: 'Kreyòl ayisyen',
}

export const getSupportedLocales = (forceProduction: boolean = false): readonly Locales[] => {
  if (
    forceProduction ||
    process?.env?.VERCEL_ENV === 'production' ||
    process?.env?.GCLOUD_PROJECT === 'cgn-school-production'
  )
    return SUPPORTED_LOCALES
  if (process?.env?.SUPPORTED_LOCALES) {
    const locales = process.env.SUPPORTED_LOCALES.split(',')
      .map((locale) => locale.trim())
      .sort() as Locales[]
    return [...locales] as const
  }
  return ['en'] as const
}

// Overload type for toI18nString
type ToI18nString = {
  (str: string | I18nString, locale?: Locales): string
  (str: string | I18nString | null | undefined, locale?: Locales): string | null | undefined
  (str: string[] | I18nStringArray, locale?: Locales): string[]
  (str: string[] | I18nStringArray | null | undefined, locale?: Locales): string[] | null | undefined
  (str: null | undefined, locale?: Locales): null | undefined
  (
    str: string | string[] | I18nString | I18nStringArray | null | undefined,
    locale?: Locales
  ): string | string[] | null | undefined
}

/**
 * Handles strings that may or may not be internationalized in dynamic content
 *
 * @param {string | string[] | I18nString | I18nStringArray | null | undefined} str - A string that may or may not be internationalized
 * @param {Locales} locale - A string indicating the current locale, defaults to 'en'.  This will be used to return the proper translated string.
 * @returns string
 */
export const toI18nString = ((str, locale = 'en') => {
  // If the string is null or undefined, return it as is
  if (str === null || str === undefined) return str

  // If the string is already a plain string or an array, return it as is
  if (typeof str === 'string' || Array.isArray(str)) return str

  // If the string is either an I18nString or I18nStringArray, return the proper locale
  if (str[locale]) return str[locale]

  // Default to returning English if the locale is not found
  if (str.en) return str.en

  console.error(`String could not be localized`, str)
  return ''
}) as ToI18nString

export const isI18nString = (str: string | string[] | I18nString | I18nStringArray): boolean => {
  return (
    typeof str === 'object' &&
    Object.keys(str).length > 0 &&
    Object.keys(str).every((key) => SUPPORTED_LOCALES.includes(key as Locales))
  )
}
